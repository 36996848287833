@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/helveticaneuecyr-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/helveticaneuecyr-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/helveticaneuecyr-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}