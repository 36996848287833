// VARIABLES
// ------------------------------------------------------------


// fonts
// ------------------------------------------------------------
$font-fallback: Arial, sans-serif;
$font: "HelveticaNeueCyr", $font-fallback;


// media variables
// ------------------------------------------------------------
$media-desktop-s: 1320px;
$media-tablet: 1023px;
$media-mobile: 767px;
$media-mobile-xs: 374px;


// media mixins
@mixin media-desktop-s {
    @media (max-width: #{$media-desktop-s}) {
        @content;
    }
}

@mixin media-tablet {
    @media (max-width: #{$media-tablet}) {
        @content;
    }
}

@mixin media-mobile {
    @media (max-width: #{$media-mobile}) {
        @content;
    }
}

@mixin media-mobile-xs {
    @media (max-width: #{$media-mobile-xs}) {
        @content;
    }
}


// media retina
@mixin media-retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @content;
    }
}















