html,
body {
    min-width: 320px;
    height: 100%;
    background: #000;
    color: #fff;
    font-size: 14px;
    line-height: normal;
    font-family: $font;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.wrapper {
    min-height: 100%;
    background: linear-gradient(261.48deg, #FA01FF 33.51%, #01D2FF 91.28%);
    overflow-x: hidden;

    @include media-mobile {
        background: linear-gradient(349.16deg, #FA00FF 11.62%, #00D1FF 92.87%);
    }
}

.inside-wrapper {
    position: relative;
    max-width: 1220px;
    margin: 0 auto;
    min-height: 910px;

    @include media-desktop-s {
        max-width: 974px;
        min-height: 715px;
    }

    @include media-tablet {
        max-width: 708px;
        min-height: 600px;
    }

    @include media-mobile {
        max-width: 450px;
        padding: 0 10px;
        min-height: none;
    }
}

.content {
    position: relative;
    z-index: 10;
    padding-top: 68px;
    padding-bottom: 30px;

    @include media-desktop-s {
        padding-top: 40px;
    }

    @include media-tablet {
        padding-top: 35px;
    }

    @include media-mobile {
        padding-top: 15px;
    }
}

.logo {
    margin-bottom: 125px;

    @include media-desktop-s {
        width: 36px;
        margin-bottom: 100px;
    }

    @include media-tablet {
        margin-bottom: 63px;
    }

    @include media-mobile {
        margin-bottom: 405px;
    }
}

.title {
    font-family: $font;
    font-weight: 900;
    font-size: 156px;
    line-height: 70%;
    letter-spacing: -7.4px;
    text-transform: uppercase;
    margin-bottom: 79px;

    @include media-desktop-s {
        font-size: 120px;
        letter-spacing: -5.7px;
        margin-bottom: 80px;
    }

    @include media-tablet {
        font-size: 82px;
        letter-spacing: -3.9px;
        margin-bottom: 50px;
    }

    @include media-mobile {
        font-size: 73px;
        letter-spacing: -3.5px;
        margin-bottom: 40px;
    }

    @include media-mobile-xs {
        font-size: 62px;
    }
}


.title-text {
    position: relative;
    margin-top: 18px;
    display: flex;

    @include media-desktop-s {
        margin-top: 15px;
    }

    @include media-tablet {
        margin-top: 10px;
    }
}


@keyframes fadeInOut {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    5% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }


    95% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    5% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.title-text-item1 {
    opacity: 0;
    animation: fadeInOut 1.5s ease-in-out;
}

.title-text-item2 {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    animation: fadeInOut 1.5s ease-in-out;
    animation-delay: 1.7s;
}

.title-text-item3 {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out;
    animation-delay: 3.4s;

    &.animated {
        opacity: 1;
    }
}


.text-wrapper {
    @include media-mobile {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}


.text {
    font-family: Helvetica, $font-fallback;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 111%;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include media-mobile {
        text-align: center;
    }
}

.buttons {
    display: flex;
    margin-bottom: 65px;

    @include media-tablet {
        flex-direction: column;
        margin-bottom: 38px;
    }

    @include media-mobile {
        align-items: center;
        margin-bottom: 5px;
    }
}

.buttons-item {
    text-decoration: none;
    margin-right: 14px;

    @include media-tablet {
        margin-right: 0;
        margin-bottom: 12px;
    }

    @include media-mobile {
        display: none;

        &.show {
            display: block;
        }
    }
}


.links {
    display: flex;
    font-size: 12px;

    @include media-mobile {
        flex-direction: column;
        font-size: 14px;
        padding: 0 20px;
    }
}

.links-item {
    @include media-mobile {
        margin-top: 11px;
    }
}

.email-link {
    color: currentColor;
    text-decoration: none;
}

.policy-link {
    color: currentColor;
    text-decoration: underline;
    margin-left: 38px;

    @include media-mobile {
        margin-left: 0;
    }
}


@keyframes items-wrapper {
    from {
        transform: scale3d(0.95, 0.95, 0.95);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}


.items-wrapper {
    animation: items-wrapper 7s linear;
    transform-origin: center center;

    @include media-mobile {
        animation: none;
    }
}


.item {
    position: absolute;
    overflow: hidden;
    transform: rotate(-15deg);
    transform-origin: right top;

    @include media-mobile {
        animation: none!important;
    }

    &__img {
        width: 100%;
    }

    &__content {
        background-color: #C4C4C4;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 12px;
        line-height: 1;
    }
}

@keyframes item1 {
    from {
        opacity: 0;
        transform: translate3d(-100%, -20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    35% {
        opacity: 0;
        transform: translate3d(-100%, -20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    65% {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }
}

.item1 {
    width: 155px;
    height: 197px;
    border-radius: 20px;
    left: 270px;
    top: -116px;
    animation: item1 1s ease-in-out;

    @include media-desktop-s {
        width: 110px;
        height: 140px;
        left: 259px;
        top: -22px;
        border-radius: 14px;
    }

    @include media-tablet {
        width: 81px;
        height: 104px;
        border-radius: 10px;
        left: 180px;
        top: -16px;
    }

    @include media-mobile {
        display: none;
    }

    &__content {
        height: 30px;
        font-size: 12px;

        @include media-desktop-s {
            height: 22px;
            font-size: 9px;
        }

        @include media-tablet {
            height: 16px;
            font-size: 7px;
        }
    }
}

@keyframes item2 {
    from {
        opacity: 0;
        transform: translate3d(-100%, -20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    20% {
        opacity: 0;
        transform: translate3d(-100%, -20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    50% {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }
}

.item2 {
    width: 81px;
    height: 104px;
    border-radius: 14px;
    left: 558px;
    top: 71px;
    z-index: 4;
    animation: item2 1s ease-in-out;

    @include media-desktop-s {
        width: 58px;
        height: 74px;
        left: 466px;
        top: 110px;
        border-radius: 10px;
    }

    @include media-tablet {
        width: 43px;
        height: 54px;
        border-radius: 7px;
        left: 332px;
        top: 82px;
    }

    @include media-mobile {
        width: 44px;
        height: 57px;
        left: 39px;
        top: 102px;
    }

    &__content {
        height: 16px;
        font-size: 8px;

        @include media-desktop-s {
            height: 11px;
            font-size: 6px;
        }

        @include media-tablet {
            height: 8px;
            font-size: 4px;
        }

        @include media-mobile {
            height: 8px;
            font-size: 4px;
        }
    }
}

@keyframes item-video {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    10% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    40% {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }
}

.item-video {
    width: 378px;
    height: 481px;
    border-radius: 50px;
    left: 612px;
    top: 44px;
    animation: item-video 1s ease-in-out;

    @include media-desktop-s {
        width: 270px;
        height: 343px;
        left: 504px;
        top: 89px;
        border-radius: 35px;
    }

    @include media-tablet {
        width: 200px;
        height: 253px;
        border-radius: 26px;
        left: 361px;
        top: 68px;
    }

    @include media-mobile {
        width: 207px;
        height: 264px;
        left: 49px;
        top: 8px;
    }

    &__content {
        height: 75px;
        font-size: 30px;

        @include media-desktop-s {
            height: 53px;
            font-size: 22px;
        }

        @include media-tablet {
            height: 40px;
            font-size: 16px;
        }

        @include media-mobile {
            height: 41px;
            font-size: 17px;
        }
    }

    &__video {
        position: absolute;
        right: 0;
        top: 0;
    }
}

@keyframes video-navs {
    from {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    80% {
        opacity: .8;
    }

    to {
        opacity: .8;
    }
}

.video-navs {
    position: absolute;
    left: 50%;
    bottom: 112px;
    transform: translateX(-50%);
    opacity: .8;
    animation: video-navs 1s ease-in-out;

    @include media-desktop-s {
        height: 111px;
        bottom: 80px;
    }

    @include media-tablet {
        height: 82px;
        bottom: 60px;
    }

    @include media-mobile {
        display: none;
    }
}


@keyframes item3 {
    from {
        opacity: 0;
        transform: translate3d(-100%, 20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    30% {
        opacity: 0;
        transform: translate3d(-100%, 20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }
}

.item3 {
    width: 169px;
    height: 215px;
    border-radius: 22px;
    left: 481px;
    top: 545px;
    animation: item3 1s ease-in-out;

    @include media-desktop-s {
        width: 120px;
        height: 153px;
        left: 412px;
        top: 449px;
        border-radius: 15px;
    }

    @include media-tablet {
        width: 89px;
        height: 113px;
        border-radius: 12px;
        left: 291px;
        top: 334px;
    }

    @include media-mobile {
        width: 103px;
        height: 131px;
        left: 315px;
        top: 39px;
    }

    &__content {
        height: 33px;
        font-size: 16px;

        @include media-desktop-s {
            height: 23px;
            font-size: 12px;
        }

        @include media-tablet {
            height: 17px;
            font-size: 8px;
        }

        @include media-mobile {
            height: 20px;
            font-size: 9px;
        }
    }
}

@keyframes item4 {
    from {
        opacity: 0;
        transform: translate3d(100%, -20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    20% {
        opacity: 0;
        transform: translate3d(100%, -20%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    50% {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }
}

.item4 {
    width: 217px;
    height: 277px;
    border-radius: 28px;
    left: 1104px;
    top: 97px;
    animation: item4 1s ease-in-out;

    @include media-desktop-s {
        width: 155px;
        height: 197px;
        left: 853px;
        top: 129px;
        border-radius: 20px;
    }

    @include media-tablet {
        width: 114px;
        height: 145px;
        border-radius: 15px;
        left: 619px;
        top: 97px;
    }

    @include media-mobile {
        width: 92px;
        height: 118px;
        left: -21px;
        top: 284px;
    }

    &__content {
        height: 43px;
        font-size: 18px;

        @include media-desktop-s {
            height: 30px;
            font-size: 12px;
        }

        @include media-tablet {
            height: 22px;
            font-size: 9px;
        }

        @include media-mobile {
            height: 18px;
            font-size: 9px;
        }
    }
}

@keyframes item5 {
    from {
        opacity: 0;
        transform: translate3d(-50%, 100%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    20% {
        opacity: 0;
        transform: translate3d(-50%, 100%, 0) scale3d(0.3, 0.3, 0.3) rotate(-15deg);
    }

    50% {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(-15deg);
    }
}

.item5 {
    width: 172px;
    height: 220px;
    border-radius: 28px;
    left: 863px;
    top: 617px;
    animation: item5 1s ease-in-out;

    @include media-desktop-s {
        width: 123px;
        height: 156px;
        left: 681px;
        top: 499px;
        border-radius: 22px;
    }

    @include media-tablet {
        width: 91px;
        height: 115px;
        border-radius: 15px;
        left: 492px;
        top: 370px;
    }

    @include media-mobile {
        width: 94px;
        height: 120px;
        left: 187px;
        top: 324px;
    }

    &__content {
        height: 33px;
        font-size: 16px;

        @include media-desktop-s {
            height: 24px;
            font-size: 12px;
        }

        @include media-tablet {
            height: 17px;
            font-size: 8px;
        }

        @include media-mobile {
            height: 18px;
            font-size: 9px;
        }
    }
}


.navs-wrapper {
    position: absolute;
    top: 516px;
    left: 788px;
    z-index: 10;
    transform: rotate(-15deg);
    opacity: .8;

    @include media-desktop-s {
        top: 367px;
        left: 613px;
    }

    @include media-tablet {
        top: 220px;
        left: 425px;
    }

    @include media-mobile {
        top: 176px;
        left: 126px;
    }
}

.navs {
    position: absolute;

    @include media-mobile {
        animation: none!important;
    }
}

@keyframes navs1 {
    from {
        opacity: 0;
        transform: translate3d(-100px, 0, 0);
    }

    50% {
        opacity: 0;
        transform: translate3d(-100px, 0, 0);
    }

    80% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.navs1 {
    top: 212px;
    left: 0;
    animation: navs1 1.2s ease-in-out;

    @include media-desktop-s {
        width: 184px;
    }

    @include media-tablet {
        width: 136px;
    }
}

@keyframes navs2 {
    from {
        opacity: 0;
        transform: translate3d(100px, 0, 0);
    }

    60% {
        opacity: 0;
        transform: translate3d(100px, 0, 0);
    }

    90% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.navs2 {
    top: 212px;
    left: 275px;
    animation: navs2 1.2s ease-in-out;

    @include media-desktop-s {
        width: 188px;
        left: 195px;
    }

    @include media-tablet {
        width: 139px;
        left: 145px;
    }
}

@keyframes navs3 {
    from {
        opacity: 0;
        transform: translate3d(100px, 0, 0);
    }

    55% {
        opacity: 0;
        transform: translate3d(100px, 0, 0);
    }

    85% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.navs3 {
    top: 135px;
    left: 187px;
    animation: navs3 1.2s ease-in-out;

    @include media-desktop-s {
        width: 179px;
        top: 157px;
        left: 133px;
    }

    @include media-tablet {
        width: 132px;
        top: 171px;
        left: 99px;
    }

}

@keyframes navs4 {
    from {
        opacity: 0;
        transform: translate3d(-100px, 0, 0);
    }

    60% {
        opacity: 0;
        transform: translate3d(-100px, 0, 0);
    }

    90% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.navs4 {
    top: 58px;
    left: 384px;
    animation: navs4 1.2s ease-in-out;

    @include media-desktop-s {
        width: 168px;
        top: 102px;
        left: 272px;
    }

    @include media-tablet {
        width: 130px;
        top: 128px;
        left: 203px;
    }
}

@keyframes navs5 {
    from {
        opacity: 0;
        transform: translate3d(0, -100px, 0);
    }

    65% {
        opacity: 0;
        transform: translate3d(0, -100px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.navs5 {
    top: -9px;
    left: 313px;
    animation: navs5 1.2s ease-in-out;

    @include media-desktop-s {
        width: 61px;
        top: 56px;
        left: 223px;
    }

    @include media-tablet {
        width: 45px;
        top: 97px;
        left: 166px;
    }
}



